import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

//导入语言包
import Tc from '../lang/tc.js'; // 繁体语言包
import En from '../lang/en.js'; // 英文语言包
import Jp from '../lang/jp.js'; // 日语语言包
import Ko from '../lang/ko.js'; // 韩语语言包


const messages = {
    tc: Tc, // 繁体语言包
    en: En, // 英文语言包
    jp: Jp, // 日语语言包
    ko: Ko, // 韩语语言包
}
window.localStorage.setItem("lang", 'ko')
export default new VueI18n({
    locale: 'ko', // set locale 默认显示英文
    fallbackLocale: 'ko', //如果语言包没有，则默认从中文中抽取
    messages: messages // set locale messages
});
