export default {
	addNew: {
		failed: "終了しました",
		success: "決済済",
		waiting: "進行中",
		submit: "に参加",
		day: "日",
		time: "時間",
		foot: "資産管理",
		tab0: "資産運用プロジェクト",
		tab1: "資産運用オーダ",
	},
	common: {
		recharge_price: "通貨価値",
		recharge_number: "チャージ数量",
		winner_money: "に利益を与える",
		trade_number: "オーダー番号",
		balance2: "口座資金",
		balance1: "現物資産価値",
		withdraw_real: "入金数量",
		withdraw_money: "引き出し数量",
		withdraw_tips: "ULink取引所の引き出し手数料は0.2%",
		withdraw_fee: "手数料",
		notice1: "まず業者口座にアップグレードしてください。詳細はカスタマーサービスに連絡してください",
		trc_address2: "Trc Address",
		trc_address: "Trc Address",
		footer_1: "トップ",
		footer_2: "ホール",
		footer_3: "オーダー",
		footer_4: "マイ",
		verify_waiting: "レビュー",
		verify_success: "成功",
		verify_failed: "に失敗",
		app_name: "ULink",
		currency_unit: "$",
		confirm: "確認",
		cancel: "キャンセル",
		save: "セーブ",
		no_data: "データなし",
		copy: "リプロダクション",
		copy_success: "コピー成功！",
		copy_error: "生殖の失敗",
		tip: "注意を引く",
		to: "に対して",
		search: "ものを探す。",
		language: "言語選択",
		freezeMessage: "担当者にご連絡ください。",
		completeOrder: "この注文でよろしいですか？",
		logout: "本当にキャンセルしますか？",
		logout_success: "成功裏に終了！",
		logout_filed: "終了失敗！",
		currency: "通貨",
		deposit: "銀行に貯金する",
		withdraw: "アボート",
		invite: "招待状",
		balance: "バランス",
		deposit_successful: "預金の成功",
		withdraw_successful: "成功裏に終了",
		deposit_fail: "預金障害",
		details: "詳細情報",
		add: "増加",
		remind: "注意事項",
		success: "成功",
		description: "指示",
		submit: "だす",
		no_record: "記録なし",
		countryCode: "国コード",
		withdraw0: "しがんしょ",
		withdraw1: "決定を待つ",
		withdraw2: "成功",
		withdraw3: "挫く",
		orderNow: "注文する",
		noBalance: "口座残高不足 ！",
		needTo: "口座の残高が不足しています！ 追加してください ",
		all: "フル",
		nodata: "データなし",
	},
	addnew: {
		notice: "こんにちは、ULinkプラットフォームへようこそ！オンラインカスタマーサービス時間は午前9時から24時まで！",
	},
	account: {
		download: "アプリのダウンロード",
		forget: "パスワードを忘れた？",
		now_login: "ログイン",
		no_account: "口座番号なし?",
		now_register: "今すぐ登録",
		welcome: "ようこそ",
		email_register: "メールボックス登録",
		phone_register: "携帯電話登録",
		email_forget: "メールボックスの復旧",
		phone_forget: "携帯電話の検索",
		cilck_obtain: "クリック",
		back_login: "カタログに戻る",
	},
	hall: {
		sell: "売りに出す",
		get_amount: "金額",
		merchant_upgrade: "マーチャント・アップグレード",
		ibuy: "私がもらうよ。",
		isell: "売りたいんだ。",
		selling_order: "連結受注",
		welfare_orders: "福祉オーダー",
		merchant: "ビジネスマン	",
		unit_price: "価格",
		number: "数量",
		surplus: "残る",
		limit: "クォーター",
		go_sell: "売ってくれ。",
		name: "名字",
		balance: "バランス",
		profit: "利益を得る",
		bank_account: "銀行口座番号",
		now_price: "現在の価格",
		optimal_price: "ベストプライス",
		confirm_buy: "購入を確認",
		confirm_sell: "売り確定",
	},
	home: {
		notice: "こんにちは、ULinkプラットフォームへようこそ！オンラインカスタマーサービスは午前9時から午後24時までご利用いただけます！",
		home_nav1: "初心者チュートリアル",
		home_nav2: "登録のご案内",
		home_nav3: "チーム一覧",
		home_nav4: "システム・ニュース",
		quick_trans: "クイックトレード",
		one_click: "ワンクリック購入",
		ctc_trans: "C2C取引",
		buy_sell: "売買",
		online: "オンラインユーザー",
		total_trans: "24時間取引高",
		message: "システム・ニュース",
		detail: "詳細",
	},
	assets: {
		recharge: "リチャージ",
		withdraw: "引き出す",
		type: "類型論",
		address: "住所",
		tip: "資金の安全のため、送金完了後、送金成功のスクリーンショットをご提出ください。",
		bank: "ATMカード",
	},
	my: {
		about: "プラットフォーム紹介",
		account_bind: "アカウント・バインディング",
		approved: "承認済み",
		account_detail: "口座詳細",
		number: "数量",
		trans_success: "取引成功",
		my_assets: "私の資産",
		edit_nickname: "ニックネームの変更",
		edit_psd: "パスワードの変更",
		team: "チーム一覧",
		invite: "登録のご案内",
		logout: "ログアウト",
		my_invite_code: "招待コード",
		invite_tip: "招待コードをコピーして、さらに友達を招待してください！",
		invite_btn: "プロモーター申請",
		team_member: "チームメンバー",
		add_month: "今月の新作",
		invite2: "招待",
		personal: "個人情報",
		avatar: "頭像",
		nickname: "ニックネーム",
		acount: "口座番号",
		invite_code: "紹介コード",
	},
	order: {
		all: "フル",
		paid: "支払い済み",
		unpaid: "デフォルト",
		detail: "Details",
		direction: "Directory",
		buy: "Buy",
		sell: "Sell",
		time: "Time",
		price: "Price",
		number: "Number",
		amount: "Amount",
		income: "InCome",
	},
	form: {
		place_old_psd: "旧パスワードを入力してください",
		place_new_psd: "新しいパスワードを入力してください。",
		place_crmnew_psd: "新しいパスワードをもう一度入力してください。",
		place_nickname: "ニックネームを入力してください",
		place_email_phone: "Eメール/携帯電話番号を入力してください",
		place_login_psd: "ログインパスワードを入力してください",
		place_email: "メールアドレスを入力してください",
		place_phone: "携帯電話番号を入力してください。",
		place_verify_code: "認証コードを入力してください。",
		place_confirm_psd: "パスワードを確認してください",
		place_invite_code: "招待コードを入力してください",
		place_usdt_number: "USDTの金額を入力してください。",
		buy_price: "購入価格",
		place_buy_price: "入札価格を入力してください",
		buy_number: "購入数量",
		place_buy_number: "ご希望の数量を入力してください。",
		sell_price: "販売価格",
		place_sell_price: "販売価格を入力してください。",
		sell_number: "販売数量",
		place_sell_number: "販売数量を入力してください。",
		nationality: "国籍",
		place_nationality: "国籍を選択してください",
		name: "名字",
		place_name: "お名前を入力してください",
		age: "年齢",
		place_age: "年齢を入力してください",
		address: "住所",
		place_address: "住所を入力してください",
		postal_code: "郵便番号",
		place_postal_code: "郵便番号を入力してください",
		phone: "電話番号",
		place_select: "選択してください",
		bank_name: "銀行名",
		place_bank_name: "銀行名を入力してください",
		bank_account: "銀行口座番号",
		place_bank_account: "銀行口座番号を入力してください。",
		id_card: "資料写真",
		place_id_card: "写真をアップロードしてください",
		place_number: "数量を入力してください",
		upload_credentials: "証明書のアップロード",
		place_upload_credentials: "証明をアップロードしてください",
		place_psd: "パスワードを入力してください",
	},
	city: {
		albania: "アルバニア",
		algeria: "アルジェリア",
		argentina: "アルゼンチン",
		armenia: "アルメニア",
		australia: "オーストラリア",
		pakistan: "パキスタン",
		austria: "オーストリア",
		bahrain: "オーストリア",
		belgium: "ベルギー",
		bosnia_and_Herzegovina: "ボスニア・ヘルツェゴビナ",
		brazil: "ブラジル",
		brunei: "ブルネイ",
		bulgaria: "ブルガリア",
		cambodia: "カンボジア",
		canada: "カナダ",
		cameroon: "カメルーン",
		chile: "チリ",
		colombia: "コロンビア",
		costa_Rica: "コスタリカ",
		croatia: "クロアチア",
		cyprus: "キプロス",
		czech_Republic: "チェコ共和国",
		denmark: "デンマーク",
		dominican_Republic: "ドミニカ共和国",
		egypt: "エジプト",
		estonia: "エストニア",
		ethiopia: "エチオピア",
		finland: "フィンランド",
		france: "フランス",
		georgia: "グルジア",
		germany: "ドイツ",
		ghana: "ガーナ",
		greece: "ギリシャ",
		guyana: "ガイアナ",
		honduras: "ホンジュラス",
		hong_Kong_China: "中国香港",
		hungary: "ハンガリー",
		iceland: "アイスランド",
		ireland: "アイルランド",
		italy: "イタリア",
		india: "インド",
		indonesia: "インドネシア",
		israel: "イスラエル",
		iran: "イラン",
		iraq: "イラク",
		japan: "日本",
		kazakstan: "カザフスタン",
		kenya: "ケニア",
		korea: "韓国",
		kuwait: "クウェート",
		kyrgyzstan: "キルギス",
		laos: "ラオス",
		latvia: "ラトビア",
		lithuania: "リトアニア",
		luxembourg: "ルクセンブルク",
		macao_China: "中国マカオ",
		macedonia: "マケドニア",
		malaysia: "マレーシア",
		malta: "マルタ",
		mexico: "メキシコ",
		moldova: "モルドバ",
		monaco: "モナコ",
		mongolia: "モンゴル",
		montenegro: "モンテネグロ共和国",
		morocco: "モロッコ",
		myanmar: "ミャンマー",
		netherlands: "オランダ",
		new_Zealand: "ニュージーランド",
		nepal: "ネパール",
		nigeria: "ナイジェリア",
		norway: "ノルウェー",
		oman: "オマーン",
		palestine: "パレスチナ",
		panama: "パナマ",
		paraguay: "パラグアイ",
		peru: "ペルー",
		philippines: "フィリピン",
		poland: "ポーランド",
		portugal: "ポルトガル",
		puerto_Rico: "プエルトリコ",
		qatar: "カタール",
		romania: "ルーマニア",
		russia: "ロシア",
		republic_of_Trinidad_and_Tobago: "トリニダード・トバゴ共和国",
		rwanda: "ルワンダ",
		saudi_Arabia: "サウジアラビア",
		serbia: "セルビア",
		singapore: "シンガポール",
		slovakia: "スロバキア",
		slovenia: "スロベニア",
		south_Africa: "南アフリカ共和国",
		spain: "スペイン",
		sri_Lanka: "スリランカ",
		sweden: "スウェーデン",
		switzerland: "スイス",
		taiwan_China: "中国台湾",
		tajikistan: "タジキスタン",
		tanzania: "タンザニア",
		thailand: "タイ",
		turkey: "トルコ",
		turkmenistan: "トルクメニスタン",
		ukraine: "ウクライナ",
		united_Arab_Emirates: "アラブ首長国連邦",
		united_Kingdom: "英国",
		united_States: "アメリカ",
		uzbekistan: "ウズベキスタン",
		venezuela: "ベネズエラ",
		vietnam: "ベトナム",
		afghanistan: "アフガニスタン",
		angola: "アンゴラ",
		azerbaijan: "アゼルバイジャン",
		bangladesh: "バングラデシュ",
		belarus: "ベラルーシ",
		belize: "ベリーズ",
		benin: "ベニン",
		bhutan: "ブータン",
		bolivia: "ボリビア",
		botswana: "ボツワナ",
		british_Virgin_Islands: "イギリス領ヴァージン諸島",
		burkina_Faso: "ブルキナファソ",
		burundi: "ブルンジ",
		cape_Verde: "カーボベルデ角",
		cayman_Islands: "ケイマン諸島",
		central_African_Republic: "中央アフリカ共和国",
		chad: "チャド",
		comoros: "コモロ",
		the_Republic_of_the_Congo: "コンゴ（布）",
		democratic_Republic_of_the_Congo: "コンゴ（金）",
		djibouti: "ジブチ",
		ecuador: "エクアドル",
		el_Salvador: "エルサルバドル",
		equatorial_Guinea: "赤道ギニア",
		eritrea: "エリトリア",
		fiji: "フィジー",
		gabon: "ガボン",
		gambia: "ガンビア",
		greenland: "グリーンランド",
		guatemala: "グアテマラ",
		guinea: "ギニア",
		haiti: "ハイチ",
		isle_of_Man: "マーン島",
		cote_d_Ivoire: "コートジボワール",
		jamaica: "ジャマイカ",
		jordan: "ヨルダン",
		lebanon: "レバノン",
		lesotho: "レソト",
		liberia: "リベリア",
		libya: "リビア",
		madagascar: "マダガスカル",
		malawi: "マラウイ",
		maldives: "モルディブ",
		mali: "マリ",
		mauritania: "モーリタニア",
		mauritius: "モーリシャス",
		mozambique: "モザンビーク",
		namibia: "ナミビア",
		nicaragua: "ニカラグア",
		republic_of_Niger: "ニジェール",
		north_Korea: "朝鲜",
		reunion: "レユニオン",
		san_Marino: "サンマリノ",
		senegal: "セネガル",
		sierra_Leone: "シエラレオネ",
		somalia: "ソマリア",
		sudan: "スーダン",
		suriname: "スリナム",
		eswatini: "スワジランド",
		syria: "シリア",
		togo: "トーゴ",
		tonga: "トンガ",
		tunisia: "チュニジア",
		united_States_Virgin_Islands: "アメリカ領ヴァージン諸島",
		uganda: "ウガンダ",
		uruguay: "ウルグアイ",
		vatican: "バチカン",
		yemen: "イエメン",
		yugoslavia: "ユーゴスラビア",
		zambia: "ザンビア",
		zimbabwe: "ジンバブエ",
		china: "中国",
	}
};
