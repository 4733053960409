export default {

	addNew: {
		failed: "Already exited",
		success: "Already settled",
		waiting: "have in hand",
		submit: "join",
		day: "day",
		time: "duration",
		foot: "conduct financial transactions",
		tab0: "Financial project",
		tab1: "Financial Order",
	},
	common: {
		recharge_price: "Currency value",
		recharge_number: "Recharge quantity",
		winner_money: "Profit",
		trade_number: "Order Number",
		balance2: "Account funds",
		balance1: "Value of spot assets",
		withdraw_real: "Received quantity",
		withdraw_money: "Withdrawal quantity",
		withdraw_tips: "The withdrawal fee for ULink exchange is 0.2%",
		withdraw_fee: "Handling fee",
		notice1: "Please upgrade to a merchant account first. For more information, please contact customer service",
		trc_address2: "Your TRC20-USDT Address",
		trc_address: "Trc Address",
		footer_1: "Home",
		footer_2: "Hall",
		footer_3: "Order",
		footer_4: "My",
		verify_waiting: "WAITING",
		verify_success: "SUCCESS",
		verify_failed: "FAILED",
		app_name: "ULink",
		currency_unit: "$",
		confirm: "Confirmed",
		cancel: "Cancel",
		save: "Save",
		no_data: "No data available",
		copy: "Copy",
		copy_success: "Copy Success！",
		copy_error: "Reproduction Failure",
		tip: "Prompts",
		to: "To",
		search: "Look for sth.",
		language: "Language",
		freezeMessage: "Please contact your supervisor",
		completeOrder: "Are you sure you want to complete this order?",
		logout: "Are you sure you want to cancel?",
		logout_success: "Exit successful！",
		logout_filed: "Failure to exit！",
		currency: "Currency",
		deposit: "Depositor",
		withdraw: "Abort",
		invite: "Attend",
		balance: "Balances",
		deposit_successful: "Deposit Success",
		withdraw_successful: "Successful exit",
		deposit_fail: "Deposit Failure",
		details: "Detailed Info",
		add: "Add",
		remind: "Reminders",
		success: "Successes",
		description: "Clarification",
		submit: "Submit",
		no_record: "No record",
		countryCode: "Country codes",
		withdraw0: "Apply for sth",
		withdraw1: "Await a decision",
		withdraw2: "Successes",
		withdraw3: "Reversal",
		orderNow: "Order Now",
		noBalance: "Insufficient available account balance ！",
		needTo: "The available balance in your account is insufficient! You need to top up ",
		all: "Full",
		nodata: "No data available",
	},
	addnew: {
		notice: "Hello, welcome to the ULink platform! The online customer service hours are from 9am to 24am!",
	},
	account: {
		download: "APP DOWNLOAD",
		forget: "Forgot Password？",
		now_login: "Login Now",
		no_account: "No Account Number?",
		now_register: "Register Now",
		welcome: "Welcome",
		email_register: "Mailbox Registration",
		phone_register: "Cell Phone Registration",
		email_forget: "Mailbox Recovery",
		phone_forget: "Cell phone retrieval",
		cilck_obtain: "Click to get",
		back_login: "Back to Login",
	},
	hall: {
		sell: "Sell",
		get_amount: "Amount",
		merchant_upgrade: "Merchant Upgrade",
		ibuy: "I'll take it.",
		isell: "I want to sell.",
		selling_order: "Consolidated Orders",
		welfare_orders: "Welfare Order",
		merchant: "Businessmen	",
		unit_price: "Unit price",
		number: "Quantity of",
		surplus: "Remain",
		limit: "Quota",
		go_sell: "Sell it.",
		name: "Name",
		balance: "Balance",
		profit: "Obtain benefits",
		bank_account: "Bank Account Number",
		now_price: "Current Price",
		optimal_price: "Best Price",
		confirm_buy: "Confirmed Buy",
		confirm_sell: "Confirmed Sell",
	},
	home: {
		notice: "Hello and welcome to the ULink platform! Online customer service is available from 9am to 24pm!",
		home_nav1: "Newbie Tutorial",
		home_nav2: "Invitation to Register",
		home_nav3: "Team List",
		home_nav4: "System News",
		quick_trans: "Quick Trade",
		one_click: "One Click Purchase",
		ctc_trans: "C2C transactions",
		buy_sell: "Buy and sell",
		online: "Online Users",
		total_trans: "Total 24H Trading Volume",
		message: "System News",
		detail: "Details",
	},
	assets: {
		recharge: "Recharge",
		withdraw: "Withdraw (cash)",
		type: "Typology",
		address: "Address",
		tip: "For the safety of your funds, please submit a screenshot of the successful transfer for review after the transfer is completed.",
		bank: "ATM card",
	},
	my: {
		about: "Platform Introduction",
		account_bind: "Account Binding",
		approved: "Approved",
		account_detail: "Account Details",
		number: "Quantities",
		trans_success: "Successful transaction",
		my_assets: "My Assets",
		edit_nickname: "Modify Nickname",
		edit_psd: "Change Password",
		team: "Team List",
		invite: "Invitation to Register",
		logout: "Logout",
		my_invite_code: "My Invitation Code",
		invite_tip: "Copy the invitation code and invite more friends to join!",
		invite_btn: "Application Promoter",
		team_member: "Team Members",
		add_month: "New this month",
		invite2: "Invitation",
		personal: "Personal Information",
		avatar: "Avatar",
		nickname: "Nickname",
		acount: "Account Number",
		invite_code: "Recommendation Code",
	},
	order: {
		all: "Full",
		paid: "Paid",
		unpaid: "Default",
		detail: "Details",
		direction: "Directory",
		buy: "Buy",
		sell: "Sell",
		time: "Time",
		price: "Price",
		number: "Number",
		amount: "Amount",
		income: "InCome",
	},
	form: {
		place_old_psd: "Please enter your old password",
		place_new_psd: "Please enter a new password",
		place_crmnew_psd: "Please enter the new password again",
		place_nickname: "Please enter your nickname",
		place_email_phone: "Please enter your e-mail/mobile phone number",
		place_login_psd: "Please enter your login password",
		place_email: "Please enter your e-mail address",
		place_phone: "Please enter your cell phone number",
		place_verify_code: "Please enter the verification code",
		place_confirm_psd: "Please confirm your password",
		place_invite_code: "Please enter the invitation code",
		place_usdt_number: "Please enter the amount of USDT",
		buy_price: "Purchase Price",
		place_buy_price: "Please enter a buy price",
		buy_number: "Purchase Quantity",
		place_buy_number: "Please enter the quantity you want to buy",
		sell_price: "Sell Price",
		place_sell_price: "Please enter the selling price",
		sell_number: "Quantity Sold",
		place_sell_number: "Please enter the quantity sold",
		nationality: "Nationality",
		place_nationality: "Please choose your nationality",
		name: "Name",
		place_name: "Please enter your name",
		age: "Age",
		place_age: "Please enter your age",
		address: "Address",
		place_address: "Please enter your address",
		postal_code: "Postal Code",
		place_postal_code: "Please enter your zip code",
		phone: "PhoneNumber",
		place_select: "Please select",
		bank_name: "Bank Name",
		place_bank_name: "Please enter your bank name",
		bank_account: "Bank Account",
		place_bank_account: "Please enter your bank account number",
		id_card: "Photo of a document",
		place_id_card: "Please upload your photo",
		place_number: "Please enter the quantity",
		upload_credentials: "Upload Certificate",
		place_upload_credentials: "Please upload certificate",
		place_psd: "Please enter your password",
	},


	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentina",
		armenia: "Armenia",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Austria",
		bahrain: "Bahrain",
		belgium: "Belgium",
		bosnia_and_Herzegovina: "Bosnia and Herzegovina",
		brazil: "Brazil",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		cambodia: "Cambodia",
		canada: "Canada",
		cameroon: "Cameroon",
		chile: "Chile",
		colombia: "Colombia",
		costa_Rica: "Costa Rica",
		croatia: "Croatia",
		cyprus: "Cyprus",
		czech_Republic: "Czech Republic",
		denmark: "Denmark",
		dominican_Republic: "Dominican Republic",
		egypt: "Egypt",
		estonia: "Estonia",
		ethiopia: "Ethiopia",
		finland: "Finland",
		france: "France",
		georgia: "Georgia",
		germany: "Germany",
		ghana: "Ghana",
		greece: "Greece",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Hungary",
		iceland: "Iceland",
		ireland: "Ireland",
		italy: "Italy",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Iran",
		iraq: "Iraq",
		japan: "Japan",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "Korea",
		kuwait: "Kuwait",
		kyrgyzstan: "Kyrgyzstan",
		laos: "Laos",
		latvia: "Latvia",
		lithuania: "Lithuania",
		luxembourg: "Luxembourg",
		macao_China: "Macao_China",
		macedonia: "Macedonia",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Mexico",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Montenegro",
		morocco: "Morocco",
		myanmar: "Myanmar",
		netherlands: "Netherlands",
		new_Zealand: " New_Zealand",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Norse",
		oman: "Oman",
		palestine: "Palestine",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "Philippines",
		poland: "Poland",
		portugal: "Portugal",
		puerto_Rico: "Puerto Rico",
		qatar: "Qatar",
		romania: "Romania",
		russia: "Russia",
		republic_of_Trinidad_and_Tobago: "Republic of Trinidad and Tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Saudi Arabia",
		serbia: "Serbia",
		singapore: "Singapore",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		south_Africa: "South Africa",
		spain: "Spain",
		sri_Lanka: "Sri Lanka",
		sweden: "Sweden",
		switzerland: "Switzerland",
		taiwan_China: "Taiwan_China",
		tajikistan: "Tajikistan",
		tanzania: "Tanzania",
		thailand: "Thailand",
		turkey: "Turkey",
		turkmenistan: "Turkmenistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "United Arab Emirates",
		united_Kingdom: "United Kingdom",
		united_States: " United_States",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaijan",
		bangladesh: "Bangladesh",
		belarus: "Belarus",
		belize: "Belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "British Virgin Islands",
		burkina_Faso: "Burkina Faso",
		burundi: "Burundi",
		cape_Verde: "Cape Verde",
		cayman_Islands: "Cayman Islands",
		central_African_Republic: "Central African Republic",
		chad: "Chad",
		comoros: "Comoros",
		the_Republic_of_the_Congo: " The_Republic_of_the_Congo",
		democratic_Republic_of_the_Congo: "Democratic Republic of the Congo",
		djibouti: "Djibouti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Equatorial Guinea",
		eritrea: "Eritrea",
		fiji: "Fiji",
		gabon: "Gabon",
		gambia: "Gambia",
		greenland: "Greenland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: " Isle_of_Man",
		cote_d_Ivoire: "Cote_d_Ivoir",
		jamaica: "Jamaica",
		jordan: "Jordan",
		lebanon: "Lebanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libya",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mozambique",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Republic_of_Niger",
		north_Korea: " North_Korea",
		reunion: "Reunion",
		san_Marino: "SAN Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalia",
		sudan: "Sudan",
		suriname: "Suriname",
		eswatini: "Eswatini",
		syria: "Syria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "United States Virgin Islands",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatican",
		yemen: "Yemen",
		yugoslavia: "Yugoslavia",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
		china: "China",
	}
};
